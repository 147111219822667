/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import HeroAlt from 'components/elements/HeroAlt'
import BoxDefaultOutline from 'components/elements/BoxDefaultOutline'
import Plaatjie from '@ubo/plaatjie'
import FormDuuf from 'components/Form'
import { FormCSS } from '../styles/FormStyle'


const Container = styled.div`
  position: relative;
`

const StyledFormDuuf = styled(FormDuuf)`
  ${FormCSS}

  .form-duuf-label-mandatory {
    display: none !important;
  }

  .form-duuf-label,
  .form-duuf-label-mandatory {
    color: rgb(213, 0, 50);
  }

  .form-duuf-file-input {
    display: none !important;
  }

  .form-duuf-submit {
    float: unset;
  }
`

const ContactBox = styled(BoxDefaultOutline)`
  position: relative;
  border: 2px solid transparent;

  &::after {
    border: 2px solid #fff;
    width: 50px;
    height: 141px;
    right: -10px;
    bottom: -51px;
    border: none;
  }

  @media (max-width: 991.98px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  @media (max-width: 991px) {
    background-color: ${(props) => props.theme.color.secondary};
    border: 1px solid ${(props) => props.theme.color.contrast};
  }
`

const ContactBoxImage = styled(Plaatjie)`
  position: absolute !important;

  @media (min-width: 992px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
    display: none !important;
  }
`
// const ContactLink = styled.p`
//   & span {
//     color: ${props => props.theme.color.text.contrast};
//     font-weight: ${props => props.theme.font.weight.xl};
//   }

//   & a {
//     color: ${props => props.theme.color.text.main};
//     text-decoration: none;
//   }
// `

export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageNewsletter {
        headernewsletter {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        contactnewsletter {
          bgimage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1056, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
          description
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { pageNewsletter, seo },
  },
}) => (
  <Layout>
    {seo && <SEO seo={seo} />}

    <HeroAlt src={pageNewsletter.headernewsletter.image} small />
    <section className="px-3 px-lg-0">
      <Container className="container pb-5">
        <div className="row pb-lg-5 mt-n5 mb-5 px-lg-3 px-md-0">
          <ContactBox className="col-lg-10 mx-auto d-flex flex-column p-5 mt-n5">
            <ContactBoxImage image={pageNewsletter.contactnewsletter.bgimage} />
            <div className="row py-lg-4 px-lg-4">
              <div className="col-12">
                <h2>{pageNewsletter.contactnewsletter.title}</h2>
                <ParseContent
                  content={pageNewsletter.contactnewsletter.description}
                />
              </div>
            </div>
            <div className="row px-lg-4 pb-4">
              <div className="col-lg-8 mb-5 mb-lg-0 form-contact">
                <StyledFormDuuf id={3} generate privacyUrlPlacement="privacyverklaring"
                  privacyUrl="/privacyverklaring" />
              </div>
            </div>
          </ContactBox>
        </div>
      </Container>
    </section>
  </Layout>
)

export default PageTemplate
